
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Input',
  data () {
    return {
      text: ''
    }
  },
  emits: ['user-input']
})
