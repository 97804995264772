
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Output',
  props: {
    output: String
  },
  methods: {
    async copyText (text: string) {
      try {
        await navigator.clipboard.writeText(text)
      } catch (e) {}
    }
  }
})
