
import { defineComponent } from 'vue'
import Header from './components/Header.vue'
import Cases from './components/Cases.vue'
import Input from './components/Input.vue'
import Output from './components/Output.vue'
import { convertCase } from './utils/switches'

export default defineComponent({
  name: 'App',
  components: {
    Header,
    Cases,
    Input,
    Output
  },
  data () {
    return {
      activeIdx: 0,
      cases: [
        'UPPERCASE',
        'lowercase',
        'Title Case',
        'camelCase',
        'PascalCase',
        'snake_case',
        'kebab-case',
        'dot.case',
        's p a c e   c a s e',
        'TOWER CASE',
        'esac esrever',
        'uGLy cAsE',
        'G0И3 ¢4S3',
        '👏Clap👏case👏'
      ],
      inputText: '',
      outputText: ''
    }
  },
  methods: {
    setActive (id: number) {
      if (this.activeIdx === id) this.activeIdx = Math.floor(Math.random() * 13)
      else this.activeIdx = id
      this.userInput(this.inputText)
    },
    userInput (text: string) {
      this.inputText = text
      if (this.inputText !== '') this.outputText = convertCase(text, this.activeIdx)
      else this.outputText = convertCase('Enter your text!', this.activeIdx)
    }
  },
  created () {
    this.userInput('Enter your text!')
  }
})
