
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Case',
  props: {
    sCase: String,
    idx: Number,
    activeIdx: Number
  },
  emits: ['set-active']
})
