
import { defineComponent } from 'vue'
import Case from './Case.vue'

export default defineComponent({
  name: 'Cases',
  props: {
    cases: Array,
    activeIdx: Number
  },
  components: {
    Case
  },
  emits: ['set-active']
})
